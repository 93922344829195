import React from 'react';
import { Link } from 'react-router-dom';

import './navigation.css';

const Navigation = props => (
    <nav>
        <ul>
        <li><Link to="/1"  id="c1" className={props.activeItem === 'c1' ? 'active' : ''} onClick={props.onClick}>PalaCUS - Palestra A</Link></li>
        <li><Link to="/2"  id="c2" className={props.activeItem === 'c2' ? 'active' : ''} onClick={props.onClick}>PalaCUS - Palestra B</Link></li>
        <li><Link to="/3"  id="c3" className={props.activeItem === 'c3' ? 'active' : ''} onClick={props.onClick}>PalaCUS - Palestra C</Link></li>
        <li><Link to="/4"  id="c4" className={props.activeItem === 'c4' ? 'active' : ''} onClick={props.onClick}>PalaCAMPUS A</Link></li>
        <li><Link to="/6"  id="c5" className={props.activeItem === 'c6' ? 'active' : ''} onClick={props.onClick}>PalaCAMPUS B</Link></li>
        <li><Link to="/5"  id="c5" className={props.activeItem === 'c5' ? 'active' : ''} onClick={props.onClick}>Sala pesi</Link></li>
        </ul>
    </nav>
);

export default Navigation;