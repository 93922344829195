import React from 'react'
import moment from 'moment'
import 'moment/locale/it'
import 'moment-timezone'

const Modal = ({ data, close }) => {

    const styles = {
        modal : {
            position : 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
            backgroundColor: '#fcfcfc',
            border: '1px solid #293b8f',
            borderRadius: '1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        button: {
            position: 'absolute',
            top: '2rem',
            right: '2rem',
            fontSize: '2rem',
            appearence: 'none',
            background: 'transparent',
            border: 'none',
            cursor: 'pointer'
        },
        day: {
            marginBottom: '.5rem'
        },
        logo: {
            width: '50px',
            height: '50px'
        }
    }

    return (
        <div style={styles.modal}>
            <button style={styles.button} onClick={close}>X</button>
            <div style={styles.inner}>
                <h1 style={styles.day}>{moment(data.event.start).format('dddd DD MMMM')}</h1>
                <h2>{moment(data.event.start).format('HH:mm')} - {moment(data.event.end).format('HH:mm')}</h2>
                <h2>{data.event.title}</h2>
            </div>
        </div>
    )
}

export default Modal