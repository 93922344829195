const CALENDAR_ID_1="unipv.it_nciohnb9t7sefcl1qjhi7u9uck@group.calendar.google.com";
const CALENDAR_ID_2="c_385qgk34qoevm860rnmvffpt10@group.calendar.google.com";
const CALENDAR_ID_3="unipv.it_fbeoi4tska28o9c3qmqdn5ql4c@group.calendar.google.com";
const CALENDAR_ID_4="unipv.it_m4727npc2mgf71e6e89evatses@group.calendar.google.com";
const CALENDAR_ID_5="c_c3mt3huucj0ae2c74ksq42e6js@group.calendar.google.com";
const CALENDAR_ID_6="c_6lv1qf5dqd2gfoob66agsppdnc@group.calendar.google.com";

const CALENDAR_API_KEY="AIzaSyBnvkFbSk-e53fkiRQvDSSzdvL-8Q5ULaU";

export {
    CALENDAR_ID_1,
    CALENDAR_ID_2,
    CALENDAR_ID_3,
    CALENDAR_ID_4,
    CALENDAR_ID_5,
    CALENDAR_ID_6,
    CALENDAR_API_KEY
}