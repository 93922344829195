import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import logo from './img/logo.svg';
import logoSfondo from './img/logo_tondo_2.svg';
import './App.css';

import Navigation from './Navigation';
import Calendar from './Calendar';
import * as k from './keys';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeMenuItem: 'c1'
    }
  }

  handleClickMenuItem = (e) => {
    const active = e.target.getAttribute('id');
    this.setState({activeMenuItem: active});
  }

  componentDidMount() {
    const pathname = window.location.pathname;
    const calendarNumber = pathname && pathname.substr(1,1);
    this.setState({activeMenuItem: `c${calendarNumber}`});
  }

  render() {


    return (
      <div className="App" style={{
        backgroundImage: `url(${logoSfondo})`,
        backgroundPosition: 'top center',
        backgroundSize: 'contain',
        backgroundRepeat: 'repeat-y',
        height: '125vh',

      }}>
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
        </header>

        <Router>
          <Navigation
            activeItem={this.state.activeMenuItem}
            onClick={this.handleClickMenuItem}
            />
          <Switch>
            <Route path="/1">
              <Calendar calendarId={k.CALENDAR_ID_1} apiKey={k.CALENDAR_API_KEY} />
            </Route>
            <Route path="/2">
              <Calendar calendarId={k.CALENDAR_ID_2} apiKey={k.CALENDAR_API_KEY} />
            </Route>
            <Route path="/3">
              <Calendar calendarId={k.CALENDAR_ID_3} apiKey={k.CALENDAR_API_KEY} />
            </Route>
            <Route path="/4">
              <Calendar calendarId={k.CALENDAR_ID_4} apiKey={k.CALENDAR_API_KEY} />
            </Route>
            <Route path="/5">
              <Calendar calendarId={k.CALENDAR_ID_5} apiKey={k.CALENDAR_API_KEY} />
            </Route>
            <Route path="/6">
              <Calendar calendarId={k.CALENDAR_ID_6} apiKey={k.CALENDAR_API_KEY} />
            </Route>
          </Switch>
        </Router>
        <a className="App-link" href="https://cuspavia.org">Sito CUS Pavia</a>
      </div>
    );
  }
}

export default App;
