import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import googleCalendarPlugin from '@fullcalendar/google-calendar';

import Modal from './Modal'

// import '@fullcalendar/core/main.css';
// import '@fullcalendar/daygrid/main.css';
// import '@fullcalendar/timegrid/main.css';

import './calendar.css';

const Calendar = props => {

    const [data, setData] = useState({});
    const [open, setOpen] = useState(false)

    const calRef = React.createRef()
    const handleRender = (info) => {
        return (
            <div
                className="element"
                style={{
                padding: '0.25rem'}}
                >
                <div className="element__time">{info.timeText}</div>
                <div className="element__title" style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                }}>{info.event.title}</div>
            </div>
        )
    }

    const handleClose = () => {
        setOpen(false);
        setData(null);
    }


    return (
        <div className="container" style={{position: 'relative'}}>
            <FullCalendar
                ref={calRef}
                locale="it"
                firstDay={1}
                allDaySlot={false}
                slotMinTime={'09:00:00'}
                plugins={[dayGridPlugin, timeGridPlugin, googleCalendarPlugin, interactionPlugin]}
                events={{
                    googleCalendarId: props.calendarId,
                    className: 'gcal-events'
                }}
                headerToolbar={{
                    left: 'prev,next',
                    center: 'title',
                    right: 'dayGridWeek,timeGridDay' // user can switch between the two
                }}
                initialView={"timeGrid"}
                eventContent={handleRender}
                dateClick={info => {
                    calRef.current.getApi().changeView('timeGridDay', info.date)
                }}
                eventClick={(info) => {
                    console.log('click', info);
                    info.jsEvent.preventDefault();
                    setData(info)
                    setOpen(true)
                }}
                googleCalendarApiKey="AIzaSyBnvkFbSk-e53fkiRQvDSSzdvL-8Q5ULaU"
            />
            {open && <Modal open={open} data={data} close={handleClose}/>}
        </div>
    )
}


export default Calendar;